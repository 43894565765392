/* eslint-disable import/prefer-default-export */

import { scroller } from "react-scroll";

export const shouldUpdateScroll = ({
  routerProps: {
    location: { state },
  },
}) => {
  const { scrollTo } = state || {};
  if (scrollTo === undefined) return true;
  scroller.scrollTo(scrollTo, {
    offset: -64,
    smooth: true,
    duration: 200,
  });
  return false;
};
